<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="8" sm="12">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(changePassword)" @reset.prevent="reset">
                <ValidationProvider name="Old Password" rules="required|min:6">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label="Old Password:"
                    label-for="old_password"
                    slot-scope="{ valid, errors }"
                  >
                    <b-form-input
                      type="password"
                      id="old_password"
                      v-model="user.old_password"
                      placeholder="Enter Old Password"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="New Password" rules="required|min:6">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label="New Password:"
                    label-for="new_password"
                    slot-scope="{ valid, errors }"
                  >
                    <b-form-input
                      type="password"
                      id="new_password"
                      v-model="user.new_password"
                      placeholder="Enter New Password"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Repeat New Password" rules="required|min:6">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label="Repeat New Password:"
                    label-for="repeat_new_password"
                    slot-scope="{ valid, errors }"
                  >
                    <b-form-input
                      type="password"
                      id="repeat_new_password"
                      v-model="user.repeat_new_password"
                      placeholder="Repeat New Password"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <b-button type="submit" variant="primary">Update</b-button>
                &nbsp;
                <b-button variant="danger" class="mr-1" type="reset" >Reset</b-button>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { changePassword } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      user: {
        old_password: '',
        new_password: '',
        repeat_new_password: ''
      }
    }
  },
  methods: {
    async changePassword () {
      await RestApi.postData(authServiceBaseUrl, changePassword, this.user)
      .then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: response.message,
            color: '#D6E09B'
          })
          this.$router.push('/')
        } else {
          this.$toast.error({
            title: 'Error',
            message: response.message
          })
          this.$refs.form.setErrors(response.errors)
        }
      })
    }
  }
}
</script>
